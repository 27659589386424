import "../css/Navigator.scss";
import PropTypes from "prop-types";

function Navigator(props) {
  const scrollToSection = (id) => {
    props.scrollToId(id);
    props.updateFocusedSection(id);
  };
  return (
    <div className="navigator-holder">
      <div className="navigator-question-box">
        <div className="text">
          <div className="typewriter">How can we help?</div>
        </div>
      </div>
      <div className="navigator-answers-holder">
        <div
          className="navigator-answer-button-holder"
          onClick={() => scrollToSection("get-coaching-section")}
        >
          <div
            className="navivator-answer-connecting-line"
            id="get-coaching-connecting-line"
          ></div>
          <div className="navigator-answer-button" id="partnership-button">
            I need coaching
          </div>
        </div>

        <div
          className="navigator-answer-button-holder"
          onClick={() => scrollToSection("partnership-section")}
        >
          <div
            className="navivator-answer-connecting-line"
            id="partnership-connecting-line"
          ></div>
          <div className="navigator-answer-button" id="become-a-coach-button">
            I want partnership
          </div>
        </div>

        <div
          className="navigator-answer-button-holder"
          onClick={() => scrollToSection("become-a-coach-section")}
        >
          <div
            className="navivator-answer-connecting-line"
            id="become-a-coach-connecting-line"
          ></div>
          <div className="navigator-answer-button" id="get-coaching-button">
            I want to be a coach
          </div>
        </div>
      </div>
    </div>
  );
}

Navigator.propTypes = {
  scrollToId: PropTypes.func,
  setFocusedItem: PropTypes.func,
};

export default Navigator;
