import "../css/Testimonials.scss";

function Testimonials() {
  const testimonials = [
    {
      name: "Judy Burghardt",
      text: "I LOST 5 lbs in just a few days!!!! I had to get onto the scale 3 x’s to make sure it was correct, lol. I’m so excited to keep going. That was a HUGE thing for me, since I had not been doing great for the last 2 weeks and staying pretty much at the same weight. Then, all of a sudden, today was the magical day I guess :)",
      // icon: "../images/Testimonials_Images/sheila-icon.png",
      style: {
        left: "32%",
        top: "230px",
        backgroundColor: "#f4f4f4",
        opacity: 0.7,
      },
    },
    {
      name: "Heather Loggins",
      text: "Yesterday was my weigh in day. Down 4 lbs from last week and 1/2”” on waist measurement. Feeling good.",
      // icon: "../images/Testimonials_Images/sheila-icon.png",
      style: {
        left: "100px",
        top: "470px",
        backgroundColor: "#f4f4f4",
      },
    },
    {
      name: "Denna Tucker",
      text: "Down 14.4 lbs and right at 9”. Why did I not find this program 25 years ago?! Well, I have found it now and a great community too :)",
      // icon: "../images/Testimonials_Images/sheila-icon.png",
      style: {
        left: "40px",
        top: "0px",
        backgroundColor: "#f4f4f4",
      },
    },
    {
      name: "Sheila Butler",
      text: "So I started this program with high blood pressure and I am happy to say it is consistently lower! Hoping to get it back to normal and it’s heading in the right direction!",
      // icon: "../images/Testimonials_Images/sheila-icon.png",
      style: {
        right: "-50px",
        top: "200px",
        backgroundColor: "#f4f4f4",
      },
    },
    {
      name: "Kathryn Houlton",
      text: "After one week on the program I can feel my energy level going up. So much so that I played a tennis match this evening and moved better than I have in a long time. I won my match which hasn’t been happening too often lately. Feeling good. Now for some rest.",
      style: { right: "80px", top: "440px" },
    },
    {
      name: "Jade Truong",
      text: "I pulled out an old dress that had been tight before I started the program and tis morning it fitted much better! It made me so happy this morning and the rest of the day.",
      style: { left: "-50px", top: "180px" },
    },
    {
      name: "Maria Ramirez",
      text: "Hello, this is Maria Ramirez, I am so happy to announce that I started a little over a week or so ago at the weight of 202 pounds. Today I may say that this morning I am down to 194 and counting. I am enjoying my new path to a new ME. I’m sleeping through the night, I feel great and not tired and run down like I used to. I am fitting loosely into some of the pants that were tight. It’s such a great feeling.",
      style: { left: "60%", top: "50px", transform: "translate(-50%, -50%)" },
    },
  ];

  let displayTestimonialsLargeScreen = testimonials.map((t, index) => {
    return (
      <div className="testimonial-box" style={t.style}>
        <div className="testimonial-text">{t.text}</div>
        <div className="testimonial-name">{t.name}</div>
        <div className="testimonial-icon">{t.name[0]}</div>
      </div>
    );
  });

  let displayTestimonialsSmallScreen = testimonials
    .slice(0, 4)
    .map((t, index) => {
      let opacity = 1 - index * 0.15;
      return (
        <div className="testimonial-box" style={{ opacity: opacity }}>
          <div className="testimonial-text">{t.text}</div>
          <div className="testimonial-name">{t.name}</div>
          <div className="testimonial-icon">{t.name[0]}</div>
        </div>
      );
    });

  return (
    <div className="testimonials-container">
      <div className="testimonials-section App-section">
        <div className="section-header">
          <div className="section-title uppercase-bolded-font gray-font">
            <span> People </span>

            <span className="uppercase-bolded-font blue-font large-font text-shadow">
              Love
            </span>
            <span className="custom-spacing"> us</span>
          </div>

          <div className="section-illustration"></div>
        </div>

        <div className="testimonials-bg">
          <div className="testimonials-small-screen">
            {displayTestimonialsSmallScreen}
          </div>
          <div className="testimonials-large-screen">
            {displayTestimonialsLargeScreen}
          </div>
          {/* <div className="read-more-button">
            <div className="read-more-text">
              <a href="/testimonials-videos">Watch videos</a>
            </div>
            <div className="read-more-line"></div>
            <div className="read-more-arrow"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
