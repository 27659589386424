import logo from "../../images/logo-no-text.svg";
import Navigator from "./Navigator";
import PropTypes from "prop-types";

import "../css/About.scss";

function About(props) {
  return (
    <div className="About-section">
      {/* <div className="About"></div> */}
      <div className="App-header">
        <img src={logo} className="cc-logo" alt="logo" />
        <div className="company-name">Committed Coaches</div>
        <div className="slogan">
          Elevating Health and Fitness Through Unrivaled Coaching Excellence
          Globally
        </div>
      </div>

      <Navigator
        scrollToId={props.scrollToId}
        updateFocusedSection={props.updateFocusedSection}
      />
    </div>
  );
}

About.propTypes = {
  scrollToId: PropTypes.func,
  updateFocusedSection: PropTypes.func,
};

export default About;
