import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuButton from "./MenuButton";
import "../css/Menu.scss";

export default function Menu(props) {
  const [curSection, setCurSection] = useState("");
  const [isOpen, setIsMenuOpen] = useState(false);
  let menuClass = isOpen ? "opened" : "closed";

  const getRef = (id) => {
    const map = props.getItemRef();
    const node = map.get(id);
    return node;
  };

  const scrollToId = (id) => {
    const node = props.getRef(id);

    node.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isOpen);
  };

  const displayMenuButtons = props.itemsList.map((item, index) => {
    return (
      <MenuButton
        key={`${item.id}-${item.index}`}
        item={item}
        index={index}
        scrollToId={scrollToId}
        curSection={curSection}
        setCurSection={setCurSection}
        getRef={getRef}
      />
    );
  });

  return (
    <div className="menu">
      <div className="menu-icon" onClick={toggleMenu}></div>
      <div className={`menu-buttons-holder ${menuClass}`}>
        {displayMenuButtons}
      </div>
    </div>
  );
}

Menu.propTypes = {
  itemsList: PropTypes.array,
  focusedItem: PropTypes.string,
  setFocusedItem: PropTypes.func,
  itemsRef: PropTypes.object,
  getItemRef: PropTypes.func,
  getRef: PropTypes.func,
};
