import "../css/Partnership.scss";

function Partnership() {
  const benefits = [
    "Instant AOV boost with personalized coaching support.",
    "Increased customer LTV and profitability through personalized accountability.",
    "Reduced refunds and chargebacks.",
    "Enhanced re-orders and positive customer experiences.",
    "The opportunity to present a free consultation with a coach as a value-added bonus during the checkout process.",
    "Our certified coaches possess extensive knowledge of your products, facilitating effective customer support.",
    "Quick and seamless setup process, complemented by step-by-step instructions and reliable technical support.",
  ];

  const displayBenefits = benefits.map((v, i) => {
    return (
      <div className="bullet-list">
        <div className="list-icon"></div>
        <div className="list-text" key={i} id={i}>
          {v}
        </div>
      </div>
    );
  });

  return (
    <div className="partnership-container App-container">
      <div className="partnership-section App-section">
        <div className="partnership-header">
          <div className="section-title uppercase-bolded-font gray-font">
            <div className="partnership-title-left">
              <span className="uppercase-bolded-font blue-font large-font text-shadow">
                Partner
              </span>
              <br></br>
              with us
            </div>
            <div className="section-illustration"></div>
            <div className="partnership-title-right">
              unlock
              <br></br>
              <span className="uppercase-bolded-font blue-font large-font text-shadow custom-spacing">
                benefits
              </span>
            </div>
          </div>
        </div>

        <div className="boxed-description partnership-description">
          <div className="description-subtext">
            <span className="blue-font medium font uppercase-bolded-font">
              Partner{" "}
            </span>
            with us <span className="gray-font">&</span> unlock
            <span className="blue-font medium-font uppercase-bolded-font">
              {" "}
              Benefits
            </span>
          </div>
          <div className="description-text">
            <div className="partnership-benefits-list">{displayBenefits}</div>
          </div>
          <div className="read-more-button">
            <div className="read-more-text">
              <a href="/partnership" rel="noreferrer" target="_blank">
                Read more{" "}
              </a>
            </div>
            <div className="read-more-line"></div>
            <div className="read-more-arrow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partnership;
