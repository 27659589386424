import { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import "./Partnership.scss";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const resizeObserverOptions = {};

const maxWidth = 800;

function Partnership() {
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("Pages", numPages);
    //setNumPages(numPages);
  }
  return (
    <div className="partnership-more-info-page" ref={setContainerRef}>
      <Document
        file={`${process.env.PUBLIC_URL}/One_page_partner_program_v3.pdf`}
        loading="Loading content..."
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={1}
          width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
        />
      </Document>
      <p className="contact-text">
        If you have any questions or would like to learn more please email us at
        <a className="email-text" href="mailto: partners@committedcoaches.com">
          partners@committedcoaches.com
        </a>
      </p>
    </div>
  );
}

export default Partnership;
