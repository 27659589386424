import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import "../css/MenuButton.scss";

export default function MenuButton(props) {
  const { item, index, scrollToId, getRef, setCurSection, curSection } = props;

  const [ref, setRef] = useState(null);
  const activeState = curSection === item.id ? "active" : "";

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setCurSection(entry.target.id);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.4,
        }
      ),
    [setCurSection]
  );

  useEffect(() => {
    setRef(getRef(item.id));
    // console.log("ref", ref);
  }, [item.id, getRef, ref]);

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
      return () => {
        observer.disconnect();
      };
    }
  }, [observer, ref]);

  const scrollToSection = () => {
    scrollToId(item.id);
    setCurSection(item.id);
  };

  return (
    <div
      key={`${item.id}-${index}`}
      className={`${item.id}-label menu-button-holder ${activeState}`}
      onClick={scrollToSection}
    >
      <div className={`${item.id}-button menu-button ${activeState}`}>
        {item.buttonName}
      </div>
    </div>
  );
}

MenuButton.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  scrollToId: PropTypes.func,
  curSection: PropTypes.string,
  setCurSection: PropTypes.func,
  getRef: PropTypes.func,
};
