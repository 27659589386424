import React, { useState, useRef } from "react";
import "./App.scss";
import About from "./sections/js/About";
import GetCoaching from "./sections/js/GetCoaching";
import BecomeACoach from "./sections/js/BecomeACoach";
import Partnership from "./sections/js/Partnership";
import Testimonials from "./sections/js/Testimonials";
import Connect from "./sections/js/Connect";
import Menu from "./components/js/Menu";

function App() {
  const [focusedSection, setFocusedSection] = useState(null);

  const itemsRef = useRef(null);

  function getMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const getRef = (id) => {
    const map = getMap();
    const node = map.get(id);
    return node;
  };

  const updateFocusedSection = (id) => {
    setFocusedSection(id);
  };

  const scrollToId = (id) => {
    const node = getRef(id);

    node.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const sections = [
    {
      name: "About",
      id: "about-section",
      buttonName: "About",
      location: (
        <About
          scrollToId={scrollToId}
          updateFocusedSection={updateFocusedSection}
        />
      ),
    },
    {
      name: "Get Coaching",
      id: "get-coaching-section",
      buttonName: "Coaching",
      location: <GetCoaching />,
    },
    {
      name: "Partnership",
      id: "partnership-section",
      buttonName: "Partnership",
      location: <Partnership />,
    },
    {
      name: "Become a coach",
      id: "become-a-coach-section",
      buttonName: "Join Us",
      location: <BecomeACoach />,
    },
    {
      name: "Testimonials",
      id: "testimonials-section",
      buttonName: "Testimonials",
      location: <Testimonials />,
    },
    {
      name: "Connect",
      id: "connect-section",
      buttonName: "Connect",
      location: <Connect />,
    },
  ];

  const displaySections = sections.map((s, i) => {
    return (
      <div
        className="section"
        ref={(node) => {
          const map = getMap();
          if (node) {
            console.log(node);
            // Add to the Map
            map.set(s.id, node);
          } else {
            // Remove from the Map
            map.delete(s.id);
          }
        }}
        id={s.id}
        key={`section-${s.id}-${i}`}
      >
        {s.location}
      </div>
    );
  });

  // const scrollTop = (e) => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <div className="App">
      <div className="App-menu">
        <Menu
          itemsList={sections}
          focusedItem={focusedSection}
          setFocusedItem={updateFocusedSection}
          itemsRef={itemsRef}
          getRef={getRef}
          getItemRef={getMap}
        />
      </div>
      {displaySections}

      <div className="footer">
        <div className="copyright-text">
          Copyright © 2024 COMMITTED COACHES LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default App;
