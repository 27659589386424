import "../css/BecomeACoach.scss";

function BecomeACoach() {
  const becomeACoach =
    "We're offering opportunities for personal and professional development, along with the necessary support and resources to seize those opportunities. Investing in your sales skills is one of the best investments you can make, and has an ROI that spans any industry. It’s typically also one of the most expensive skills to acquire; until now. Committed Coaches provides world class sales training and support from expert sales coaches, for free. In fact, committed coaches get paid to put their skills to the test through opportunities we create for our coaches, so that you can improve your skill set while helping people change their lives.";

  return (
    <div className="become-a-coach-container">
      <div className="become-a-coach-section App-section">
        <div className="section-header">
          <div className="section-illustration"></div>
        </div>

        <div className="boxed-description">
          <div className="description-subtext">
            <span>Be part of</span>
            <span className="custom-spacing"> something </span>
            <span className="uppercase-bolded-font blue-font large-font text-shadow">
              greater
            </span>{" "}
            <br></br>
            <span className="gray-font">
              Join a leader in the health and wellness space.
            </span>
          </div>
          <div className="description-text gray-font">{becomeACoach}</div>
          <div className="read-more-button">
            <div className="read-more-text">
              <a
                href="https://forms.gle/SMYAs3QnJ4wUfRTR8"
                rel="noreferrer"
                target="_blank"
              >
                Apply
              </a>
            </div>
            <div className="read-more-line"></div>
            <div className="read-more-arrow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BecomeACoach;
