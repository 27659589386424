import "../css/Connect.scss";

function Connect() {
  return (
    <div className="Contact-section App-section">
      <div className="contact-section-text">
        <div className="large-text">CONNECT</div>
        <div className="small-text">with us</div>
      </div>
      <div className="contact-buttons-holder">
        <a href="mailto: support@committedcoaches.com">
          <div className="contact-button email-button">
            <div className="email-icon"></div>
            <div className="email-text">support@committedcoaches.com</div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Connect;
